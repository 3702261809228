import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import fact from './content/facts.json';
import { ATextDisplay } from './components/ATextDisplay';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "v-factsassumptions-table"
    }}>{`V. Facts/Assumptions Table`}</h1>
    <br />
    <h3 {...{
      "id": "a1"
    }}>{`A1`}</h3>
    <ATextDisplay content={fact.a1} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a2"
    }}>{`A2`}</h3>
    <ATextDisplay content={fact.a2} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a3"
    }}>{`A3`}</h3>
    <ATextDisplay content={fact.a3} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a4"
    }}>{`A4`}</h3>
    <ATextDisplay content={fact.a4} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a5"
    }}>{`A5`}</h3>
    <ATextDisplay content={fact.a5} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a6"
    }}>{`A6`}</h3>
    <ATextDisplay content={fact.a6} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a7"
    }}>{`A7`}</h3>
    <ATextDisplay content={fact.a7} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a8"
    }}>{`A8`}</h3>
    <ATextDisplay content={fact.a8} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a9"
    }}>{`A9`}</h3>
    <ATextDisplay content={fact.a9} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a10"
    }}>{`A10`}</h3>
    <ATextDisplay content={fact.a10} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a11"
    }}>{`A11`}</h3>
    <ATextDisplay content={fact.a11} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a12"
    }}>{`A12`}</h3>
    <ATextDisplay content={fact.a12} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a13"
    }}>{`A13`}</h3>
    <ATextDisplay content={fact.a13} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a14"
    }}>{`A14`}</h3>
    <ATextDisplay content={fact.a14} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a15"
    }}>{`A15`}</h3>
    <ATextDisplay content={fact.a15} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a16"
    }}>{`A16`}</h3>
    <ATextDisplay content={fact.a16} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a17"
    }}>{`A17`}</h3>
    <ATextDisplay content={fact.a17} mdxType="ATextDisplay" />
    <hr></hr>
    <h3 {...{
      "id": "a18"
    }}>{`A18`}</h3>
    <ATextDisplay content={fact.a18} mdxType="ATextDisplay" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      